.dropdown-toggle::after {
  display: none;
}

a:hover {
  text-decoration: none;
}

.dropdown-menu {
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-1 {
  font-size: 3em;
}

.fs-2 {
  font-size: 2.5em;
}

.fs-3 {
  font-size: 2.0em;
}

.fs-4 {
  font-size: 1.5em;
}

.fs-5 {
  font-size: 1.0em;
}