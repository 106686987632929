.Products {

    .list-cover {
        position: relative;
        width: 35px;
        height: 35px;
        overflow: hidden;

        img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}