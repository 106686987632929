.NewOrder {

    .cart-card {

        .cart-item {
            position: relative;

            &:hover {

                .item-wrapper {
                    transform: translateX(-70px);
                }
            }

            .item-wrapper {
                position: relative;
                transition: all 0.6s ease;
                background-color: #fff;
                z-index: 2;
            }

            .qty-toggle {
                position: absolute;
                top: 0; right: 0;
                height: 100%;
                width: 70px;
                background-color: #fff;
                z-index: 1;
            }
        }
    }
}