.ProfilePage {

    .profile-picture {
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin: auto;
        border-radius: 50%;

        img {
            width: 100%;
        }
    }
}